base rules
*{
    margin: 0;
    padding: 0;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif;
}

p{
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #6c757d;
}

.r-p{
    padding-top: 80px;
    padding-bottom: 80px;
}

body{
    /* background: #dbd8d8; */
    background: url("./images/curt1.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.r-btn{
    padding: 5px 20px;
    background-color: #2f00ff !important;
    color: #fff !important;
}

.preloader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 99998;
}
.preloader:before{
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: #000;
    transition: 1s;
}

.preloader:after{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    background: #000;
    transition: 1s;
}

.preloader.complete:before{
    bottom: -50%;
}
.preloader.complete:after{
    top: -50%;
}
.preloader.complete{
    visibility: hidden;
}
.circle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.1);
    border-top: 10px solid #ee8683;
    animation: animate 1.5s infinite linear;
    z-index: 99999;
}

@keyframes animate{
    0%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100%{
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/* Navigation */

nav.r-nav{
    /* background: transparent; */
    background: url("./images/curtain2.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px 0;
    transition: .5s all;
}

nav .navbar-brand{
    color: #fff;
    transition: .5s all;
    font-size: 5.5vh;
    font-family: 'Nabla', cursive;
/* font-family: 'Pangolin', cursive; */
}

nav .navbar-nav li a{
    color: rgb(255, 253, 154) !important;
    font-size: 22px;
    letter-spacing: 0.05em;
    font-weight: 600;
    /* font-family: 'Nunito', sans-serif; */
    font-family: 'Pangolin', cursive;
}

nav .navbar-nav li{
    margin-left: 15px;
}

nav.r-nav.stickyadd{
    background: #f9ecec !important;
    padding: 15px 0;
    box-shadow: 0 1px 8px 3px rgba(0, 0, 0, 0.05);
    transition: .5s all ease-in-out;
}

nav.stickyadd .navbar-brand{
    color:#000;
    font-size: 40px;
    transition: 0.5s all;
}

nav.stickyadd .navbar-nav .nav-link{
    color: #000 !important;
}

.navbar .hamburger{
    color: #fff;
}

nav.stickyadd .hamburger{
    color: #000;
}


/* Header */

header{
    height: 18vh;
    width: 100%;
    /* background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) ; */
    background: url("./images/curtain2.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


/* Contact */



footer{
    height: 100px;
    background-color: #333333;
}




/* boxes */

* {
    box-sizing: border-box;
  }
  body {
    font-size: 14px;
  }
  .v2_2 {
    width: 100vw;
    height: 230px;
    /* background: url("../images/v2_2.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v1_3 {
    width: 100vw;
    height: 230px;
    background: rgb(250, 235, 207);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    overflow: hidden;
  }
  .v4_3 {
    width: 98.5vw;
    height: 212px;
    /* background: url("../images/v4_3.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 10px;
    left: 10px;
    border: 1px solid rgba(0,0,0,1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }
  .photo {
    width: 115px;
    height: 153px;
    /* background: url("../images/v1_4.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    position: absolute;
    top: 22px;
    left: 20px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    overflow: hidden;
  }

  .photo img{
    width: 100%;
    height: 100%;
  }
  .v1_9 {
    width: 626px;
    color: rgb(163, 32, 32);
    position: absolute;
    top: 22px;
    left: 177px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, .27);
    font-family: Work Sans;
    font-weight: Bold;
    font-size: 30px;
    text-align: left;
  }
  .v1_39 {
    width: 398px;
    color: rgba(0,0,0,1);
    position: absolute;
    top: 69px;
    left: 177px;
    font-family: Work Sans;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    text-align: left;
  }
  .v1_41 {
    width: 265px;
    color: rgba(33,28,255,1);
    position: absolute;
    top: 156px;
    left: 177px;
    font-family: Work Sans;
    font-weight: Regular;
    font-size: 18px;
    opacity: 1;
    text-align: left;
  }
  .v1_42 {
    width: 140px;
    color: rgba(0,0,0,1);
    position: absolute;
    top: 185px;
    left: 105px;
    font-family: Work Sans;
    font-weight: Regular;
    font-size: 12px;
    opacity: 1;
    text-align: left;
  }
  .v1_43 {
    width: 372px;
    color: rgb(187, 6, 6);
    position: absolute;
    top: 191px;
    left: 177px;
    font-family: Work Sans;
    font-weight: Regular;
    font-size: 20px;
    opacity: 1;
    text-align: left;
  }

  .address-link{
    white-space: pre-line;
  }

 
  input[type="text"] {
    width: 90%;
    height: 70px;
    font-size: 35px;
  }
  
  input[type="text"]::placeholder {
    color: #ffbb00;
  }

  input {
  color: #fff;
  font-family: Nunito;
  padding: 2px;
  margin: 2px;
  border: 2px;
  background-color: transparent;
  background-color: #000;
}

.search-input-container {
    position: relative;
    background-image: url('./images/curtain2.jpeg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  
  .search-icon {
    font-size: 25px;
    position: absolute;
    top: 50%;
    right: 6vw;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .policy{
    color: #e7e8e9;
  }